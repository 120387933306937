<script lang="tsx">
import type { PropType, SlotsType } from 'vue'

export type BaseUiProgressBarProps<T> = {
    progress: number
    max?: number
    breakpoint?: number | number[]
    breakpoints?: number[]
}

type BaseUiProgressBarSlots<T> = {}

type ComponentOptions = {}

export function defineComponentBaseUiProgressBar<T>(options?: ComponentOverrideOptions<ComponentOptions, BaseUiProgressBarProps<T>, BaseUiProgressBarSlots<T>>) {
    return defineComponent(
        (props: BaseUiProgressBarProps<T>, ctx) => {

            const progressPercentage = computed<number>(() => {
                if ((props.max ?? 0) <= 0 || props.progress <= 0) return 0
                return Math.min(100, Math.floor((props.progress / (props.max ?? 100)) * 100))
            })

            const progressClass = computed<string | null>(() => {
                const breakpoints = (props.breakpoints ?? (Array.isArray(props.breakpoint) ? props.breakpoint : props.breakpoint ? [props.breakpoint] : []))
                    .sort((a, b) => b - a)

                for (const breakpoint of breakpoints) {
                    if (progressPercentage.value >= breakpoint) {
                        return `sim-progress--p-${breakpoint}`
                    }
                }

                return null
            })

            return () => (
                <progress
                    class={['sim-progress', progressClass.value]}
                    value={progressPercentage.value}
                    max="100"
                >
                    {`${progressPercentage.value}%`}
                </progress>
            )
        },
        {
            props: {
                progress: {
                    type: Number as PropType<BaseUiProgressBarProps<T>['progress']>,
                    default: options?.props?.progress?.default,
                    required: options?.props?.progress?.required ?? true,
                },
                max: {
                    type: Number as PropType<BaseUiProgressBarProps<T>['max']>,
                    default: options?.props?.max?.default ?? 100,
                    required: options?.props?.max?.required ?? false,
                },
                breakpoint: {
                    type: [Number, Array] as PropType<BaseUiProgressBarProps<T>['breakpoint']>,
                    default: options?.props?.breakpoint?.default,
                    required: options?.props?.breakpoint?.required ?? false,
                },
                breakpoints: {
                    type: Array as PropType<BaseUiProgressBarProps<T>['breakpoints']>,
                    default: options?.props?.breakpoints?.default,
                    required: options?.props?.breakpoints?.required ?? false,
                },
            },
            slots: Object as SlotsType<BaseUiProgressBarSlots<T>>,
            emits: {},
        }
    )
}

export default defineComponentBaseUiProgressBar()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiProgressBar.scss" as *;

@include progress {
    border-radius: 100vmax;
    height: 0.5rem;
}

@include progress-bar {
    background-color: #F2F2F2;
}

@include progress-value {
    background-color: #22c55e;
}

</style>
